// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Utils = require("../../../../utils/Utils.bs.js");
var React = require("react");
var $dot$dot$slash$dot$dot$slash$dot$dot$slash = require("../../../");
var Icon__Jsx3 = require("../Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../../reason/common/Theme/Theme__Color.bs.js");
var Touchable__Jsx3 = require("../Touchable/Touchable__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Colors = require("uikit/styles/colors");

function autocompleteToString(autocomplete) {
  if (typeof autocomplete === "object") {
    return autocomplete.VAL;
  } else if (autocomplete === "new_password") {
    return "new_password";
  } else if (autocomplete === "username") {
    return "username";
  } else {
    return "current_password";
  }
}

var make = $dot$dot$slash$dot$dot$slash$dot$dot$slash.InputField;

var JS = {
  make: make
};

function Form__Jsx3$InputField(props) {
  return JsxRuntime.jsx(make, {
              labelText: props.labelText,
              type: props._type,
              autocomplete: Core__Option.map(props.autocomplete, autocompleteToString),
              autofocus: props.autofocus,
              optionalText: props.optionalText,
              placeholderText: props.placeholderText,
              className: props.className,
              onChange: props.onChange,
              onKeyDown: props.onKeyDown,
              onBlur: props.onBlur,
              disabled: props.disabled,
              onFocus: props.onFocus,
              status: props.status,
              prepend: props.prepend,
              append: props.append,
              value: props.value,
              defaultValue: props.defaultValue,
              id: props.id,
              ref: props.inputRef
            });
}

var InputField = {
  JS: JS,
  make: Form__Jsx3$InputField
};

var make$1 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.TextareaField;

var TextAreaField = {
  make: make$1
};

var make$2 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Checkbox;

var Checkbox = {
  make: make$2
};

var make$3 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Radio;

var Radio = {
  make: make$3
};

var make$4 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.RadioGroup;

var RadioGroup = {
  make: make$4
};

var make$5 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.RadioGroupItem;

var RadioGroupItem = {
  make: make$5
};

var make$6 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Switch;

var Switch = {
  make: make$6
};

var style_color = Colors.crimson;

var style_display = "block";

var style_fontSize = "14px";

var style_fontWeight = "500";

var style_lineHeight = "16px";

var style_marginTop = "5px";

var style = {
  color: style_color,
  display: style_display,
  fontSize: style_fontSize,
  fontWeight: style_fontWeight,
  lineHeight: style_lineHeight,
  marginTop: style_marginTop
};

function Form__Jsx3$Error(props) {
  return JsxRuntime.jsx("span", {
              children: props.children,
              style: style
            });
}

var $$Error = {
  style: style,
  make: Form__Jsx3$Error
};

var style_color$1 = Colors.goldTips;

var style_display$1 = "block";

var style_fontSize$1 = "14px";

var style_fontWeight$1 = "500";

var style_lineHeight$1 = "16px";

var style_marginTop$1 = "5px";

var style$1 = {
  color: style_color$1,
  display: style_display$1,
  fontSize: style_fontSize$1,
  fontWeight: style_fontWeight$1,
  lineHeight: style_lineHeight$1,
  marginTop: style_marginTop$1
};

function Form__Jsx3$Warning(props) {
  return JsxRuntime.jsx("span", {
              children: props.children,
              style: style$1
            });
}

var Warning = {
  style: style$1,
  make: Form__Jsx3$Warning
};

var style_0 = Css.position("absolute");

var style_1 = {
  hd: Css.right({
        NAME: "px",
        VAL: 7
      }),
  tl: {
    hd: Css.bottom({
          NAME: "px",
          VAL: 7
        }),
    tl: {
      hd: Css.height({
            NAME: "px",
            VAL: 21
          }),
      tl: {
        hd: Css.width({
              NAME: "px",
              VAL: 21
            }),
        tl: {
          hd: Css.borderRadius({
                NAME: "px",
                VAL: 11
              }),
          tl: {
            hd: Css.paddingLeft({
                  NAME: "px",
                  VAL: 1
                }),
            tl: {
              hd: Css.backgroundColor(Theme__Color.stTropaz),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.color(Theme__Color.white),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var style$2 = {
  hd: style_0,
  tl: style_1
};

function Form__Jsx3$SearchField$SearchFieldGoButton(props) {
  return JsxRuntime.jsx(Touchable__Jsx3.make, {
              style: style$2,
              onPress: props.onPress,
              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                    icon: "ArrowRight",
                    size: 9
                  })
            });
}

var SearchFieldGoButton = {
  style: style$2,
  make: Form__Jsx3$SearchField$SearchFieldGoButton
};

var style_0$1 = Css.position("absolute");

var style_1$1 = {
  hd: Css.right({
        NAME: "px",
        VAL: 9
      }),
  tl: {
    hd: Css.bottom({
          NAME: "px",
          VAL: 7
        }),
    tl: {
      hd: Css.height({
            NAME: "px",
            VAL: 21
          }),
      tl: {
        hd: Css.width({
              NAME: "px",
              VAL: 21
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.color(Theme__Color.silverSand),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Theme__Color.doveGray),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var style$3 = {
  hd: style_0$1,
  tl: style_1$1
};

function Form__Jsx3$SearchField$SearchFieldClearButton(props) {
  return JsxRuntime.jsx(Touchable__Jsx3.make, {
              style: style$3,
              onPress: props.onPress,
              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                    icon: "ReactSelectCancel",
                    size: 20
                  })
            });
}

var SearchFieldClearButton = {
  style: style$3,
  make: Form__Jsx3$SearchField$SearchFieldClearButton
};

function toArray(str) {
  return str.trim().split(",").map(function (prim) {
                return prim.trim();
              }).filter(function (str) {
              return str !== "";
            });
}

function fromArray(arr) {
  return Utils.$$Array.joinWith(", ", arr);
}

function Form__Jsx3$SearchField(props) {
  var __onFocus = props.onFocus;
  var __onBlur = props.onBlur;
  var __onChange = props.onChange;
  var value = props.value;
  var onChange = __onChange !== undefined ? __onChange : (function (prim) {
        
      });
  var onBlur = __onBlur !== undefined ? __onBlur : (function (prim) {
        
      });
  var onFocus = __onFocus !== undefined ? __onFocus : (function (prim) {
        
      });
  var match = React.useState(function () {
        return fromArray(value);
      });
  var setTempValue = match[1];
  var tempValue = match[0];
  React.useEffect((function () {
          setTempValue(function (param) {
                return fromArray(value);
              });
        }), [value]);
  return JsxRuntime.jsxs(View__Jsx3.make, {
              flexGrow: 1,
              position: "relative",
              children: [
                JsxRuntime.jsx(Form__Jsx3$InputField, {
                      labelText: props.labelText,
                      _type: "search",
                      placeholderText: props.placeholderText,
                      className: Css.style({
                            hd: Css.flexGrow(1),
                            tl: /* [] */0
                          }),
                      onChange: (function (e) {
                          var newValue = e.target.value;
                          setTempValue(function (param) {
                                return newValue;
                              });
                        }),
                      onKeyDown: (function (e) {
                          if (e.key === "Enter") {
                            return onChange(toArray(tempValue));
                          }
                          
                        }),
                      onBlur: (function (e) {
                          onBlur(e);
                          onChange(toArray(tempValue));
                        }),
                      onFocus: (function (e) {
                          onFocus(e);
                        }),
                      value: tempValue
                    }),
                tempValue.trim() !== fromArray(value) ? JsxRuntime.jsx(Form__Jsx3$SearchField$SearchFieldGoButton, {
                        onPress: (function (param) {
                            onChange(toArray(tempValue));
                          })
                      }) : (
                    value.length !== 0 ? JsxRuntime.jsx(Form__Jsx3$SearchField$SearchFieldClearButton, {
                            onPress: (function (param) {
                                setTempValue(function (param) {
                                      return "";
                                    });
                                onChange([]);
                              })
                          }) : null
                  )
              ]
            });
}

var SearchField = {
  SearchFieldGoButton: SearchFieldGoButton,
  SearchFieldClearButton: SearchFieldClearButton,
  toArray: toArray,
  fromArray: fromArray,
  make: Form__Jsx3$SearchField
};

var Label;

var HelpLabel;

var RadioButton;

var RadioButtonGroup;

exports.Label = Label;
exports.HelpLabel = HelpLabel;
exports.autocompleteToString = autocompleteToString;
exports.InputField = InputField;
exports.TextAreaField = TextAreaField;
exports.Checkbox = Checkbox;
exports.Radio = Radio;
exports.RadioGroup = RadioGroup;
exports.RadioGroupItem = RadioGroupItem;
exports.RadioButton = RadioButton;
exports.RadioButtonGroup = RadioButtonGroup;
exports.Switch = Switch;
exports.$$Error = $$Error;
exports.Warning = Warning;
exports.SearchField = SearchField;
/* make Not a pure module */
